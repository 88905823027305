<template>
  <div
    class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 mt-5"
  >
    <div
      class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
    >
      <div>
        <h3
          class="text-3xl font-bold lg:text-5xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-green-400"
        >
          ขั้นตอนการใช้งาน
        </h3>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-4">
      <div
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <center>
          <img style="width: 70%" src="@/assets/svg/booking.svg" />
        </center>

        <br />

        <h2
          class="text-4xl font-bold lg:text-5xl xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-purple-300"
        >
          จอง
        </h2>
        <p
          class="text-sm font-medium tracking-widest uppercase lg:text-base transition duration-300 ease-in-out"
        >
          เลือกจองรุ่นรถ EV ที่คุณสนใจ
        </p>
      </div>
      <div
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <center>
          <img style="width: 70%" src="@/assets/svg/travel.svg" />
        </center>

        <br />

        <h2
          class="text-4xl font-bold lg:text-5xl xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-purple-300"
        >
          รับ
        </h2>
        <p class="text-sm font-medium tracking-widest uppercase lg:text-base">
          เลือกจองรุ่นรถ EV ที่คุณสนใจ
        </p>
      </div>
      <dive
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <center>
          <img style="width: 70%" src="@/assets/svg/travel.svg" />
        </center>
        <h6
          class="text-4xl font-bold lg:text-5xl xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-purple-300"
        >
          ขับ
        </h6>
        <p class="text-sm font-medium tracking-widest uppercase lg:text-base">
          สัมผัสประสบการณ์ EV ที่คุณออกแบบเองได้ หมดกังวลเรื่องค่าใช้จ่ายจุกจิก
        </p>
      </dive>
      <dive
        class="mt-2 text-center cursor-pointer text-gray-100 hover:text-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <center>
          <img style="width: 70%" src="@/assets/svg/place.svg" />
        </center>
        <h6
          class="text-4xl font-bold lg:text-5xl xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-purple-300"
        >
          คืน
        </h6>
        <p class="text-sm font-medium tracking-widest uppercase lg:text-base">
          หลังใช้งานเรียบร้อย เลือกจุดนัดหมายเพื่อให้เรารับรถคืน สะดวก
          ไม่ต้องเดินทาง
        </p>
      </dive>
    </div>
  </div>
</template>
