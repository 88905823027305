<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
  >
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2
        class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto uppercase"
      >
        <span class="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                x="0"
                y="0"
                width=".135"
                height=".30"
              >
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect
              fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
              width="52"
              height="24"
            ></rect>
          </svg>
        </span>
        VOLTZ the bitcoin and crypto currencies !
      </h2>
    </div>

    <div class="mx-auto max-w-screen-md">
      <h4 class="font-sans text-2xl font-bold">What is VOLTZ ?</h4>

      <p class="text-gray-300 font-sans text-1xl">
        Voltz (VOLTZ) want to make the use of electric trains occur widely and
        more concrete Therefore, an electric vehicle charging station project
        was born. (VOLTZ Charging Station) that provides free electric vehicle
        service.
      </p>

      <p class="text-gray-300 font-sans text-1xl">
        Nowadays, we can't deny the demand for cars. The more cars, the need for
        energy to power the cars is also important. But nowadays, most cars are
        powered by gasoline. causing pollution and rising prices With the
        advantages of 100% electric cars, whether saving costs and maintenance
        costs, reducing pollution, quietness and acceleration of the driver's
        heart. with these advantages As a result, many governments in Countries
        come together to support electric vehicles. and take advantage of There
        are more and more electric cars in all regions of the world and in
        Thailand itself, it has begun to awaken the trend. electric car trend
        But still not very popular, it may be an important factor of electric
        cars that are not enough for meet the needs That is the electric
        charging station itself. Voltz Energy Co., Ltd. foresees such
        limitations. pointed out that he wanted to be a part in helping drive
        the trend of Electric cars in Thailand grow therefore creating a
        platform for general public can co-own the charger stations for electric
        cars and we will continue to increase The popularity of electric cars,
        such as generating income from Rent an electric car or bring an electric
        car to join future taxi
      </p>
    </div>

    <div
      class="grid gap-10 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3 mt-10"
    >
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 shadow"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Metamask</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow"
          src="https://play-lh.googleusercontent.com/-3uTwEsZDk2NEgRblDEfIIY7T-xAZfJPN5JzVKz7s94Ds8KrKCrSVHvkEuneJlUBekc"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Trust wallet</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow p-2"
          style="border: 2px solid white; border-radius: 50%"
          src="https://cryptologos.cc/logos/safepal-sfp-logo.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">Safepal</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
      <div class="flex">
        <img
          class="object-cover w-20 h-20 mr-4 rounded-full shadow"
          src="https://pbs.twimg.com/profile_images/1376939922423128064/Pj-UFtCq_400x400.png"
          alt="Person"
        />
        <div class="flex flex-col justify-center">
          <p class="text-lg font-bold">ALL Wallet</p>
          <p class="text-sm text-gray-400">BSC Chain</p>
        </div>
      </div>
    </div>
  </div>
</template>
