<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
  >
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <div>
        <p
          class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400"
        >
          Roadmap and Product Planing
        </p>
      </div>
      <h2
        class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
      >
        <span class="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                x="0"
                y="0"
                width=".135"
                height=".30"
              >
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect
              fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
              width="52"
              height="24"
            ></rect>
          </svg>
        </span>
        Roadmap
      </h2>
    </div>
    <div class="grid gap-6 row-gap-10 lg:grid-cols-2">
      <div class="lg:py-6 lg:pr-16">
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">2022 Q3</p>
            July 2022 <br />
            ✅ Project Initiation <br />
            ✅ First Phase of Smart Contract Editing and Deployment Test <br />
            ✅ Roadmap Confirmation <br />
            ✅ Description of the idea and concept of the game <br />

            August 2022 (Click to earn Phase, DEX system)<br />
            ✅ Website Mobile Support <br />
            ✅ Referral program <br />
            ✅ Automate Deposit, Withdrawal <br />
            ✅ Transparency Explorer <br />
            ✅ Live Transactions <br />
            ⬜️ Rank Holder system <br />
            ⬜️ Staking to earn <br />
            ⬜️ Add LP, Remove LP, Swap<br />
            ⬜️ Chart, Trading Terminal<br />

            September 2022 (PVE Phase)<br />
            ⬜️ DAO Vote system<br />
            ⬜️ Composing game scripts <br />
            ⬜️ Development of a prototype game and economy <br />
            ⬜️ Draw characters and items <br />
            ⬜️ Pre-Launch Page Design Completion <br />
            ⬜️ Standard packs released and sales <br />
            ⬜️ Event System Completion <br />
            ⬜️ Content creator support program <br />
            ⬜️ Artifacts System <br />
            ⬜️ PVE Arena
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">2022 Q4</p>
            <p class="text-gray-400">
              October (PVP Phase)<br />
              ⬜️ Rank System<br />
              ⬜️ PVP Arena
            </p>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">2023 Q1</p>
            <p class="text-gray-400">No plans yet.</p>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-arrow-down"></i>
              </div>
            </div>
            <div class="w-px h-full bg-gray-300"></div>
          </div>
          <div class="pt-1 pb-8">
            <p class="mb-2 text-lg font-bold">2023 Q2</p>
            <p class="text-gray-400">No plans yet.</p>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col items-center mr-4">
            <div>
              <div
                class="flex items-center justify-center w-10 h-10 border rounded-full"
              >
                <i class="fad fa-check"></i>
              </div>
            </div>
          </div>
          <div class="pt-1">
            <p class="mb-2 text-lg font-bold">Next...</p>
            <p class="text-gray-400"></p>
          </div>
        </div>
      </div>
      <div class="relative">
        <img
          class="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
          src="/img/rocket.jpeg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
